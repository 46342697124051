// extracted by mini-css-extract-plugin
export var homeWebDevProcess = "cy_tc";
export var homeWebDevProcess__between = "cy_td";
export var homeWebDevProcess__contentFive = "cy_tn";
export var homeWebDevProcess__contentFour = "cy_tm";
export var homeWebDevProcess__contentOne = "cy_tj";
export var homeWebDevProcess__contentThree = "cy_tl";
export var homeWebDevProcess__contentTwo = "cy_tk";
export var homeWebDevProcess__description = "cy_th";
export var homeWebDevProcess__image = "cy_tf";
export var homeWebDevProcess__title = "cy_tg";