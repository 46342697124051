// extracted by mini-css-extract-plugin
export var companyInfo__card = "ch_sg";
export var companyInfo__cardText = "ch_sj";
export var companyInfo__cardTitle = "ch_sh";
export var companyInfo__description = "ch_sf";
export var companyInfo__flex = "ch_sp";
export var companyInfo__head = "ch_sn";
export var companyInfo__headSm = "ch_sm";
export var companyInfo__title = "ch_sd";
export var companyInfo__topWrapper = "ch_sl";
export var companyInfo__wrapper = "ch_sk";