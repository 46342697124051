// extracted by mini-css-extract-plugin
export var succesStoriesCard = "fG_Nv";
export var succesStoriesCardRow = "fG_NG";
export var succesStoriesCard__bottomWrapper = "fG_NB";
export var succesStoriesCard__category = "fG_ND";
export var succesStoriesCard__categoryWrapper = "fG_NC";
export var succesStoriesCard__description = "fG_Nz";
export var succesStoriesCard__image = "fG_Nx";
export var succesStoriesCard__locaation = "fG_NF";
export var succesStoriesCard__logosWrapper = "fG_Ny";
export var succesStoriesCard__title = "fG_Nw";